import { signOut } from 'next-auth/react';
import { useCallback } from 'react';

import backendService from '../services/backendService';

async function attemptClearCache() {
  try {
    await backendService.instance.users.clearCache();
  } catch (error) {}
}

function useSignOut() {
  const b2cSignout = useCallback(async (redirectUrl?: string) => {
    const url = await backendService.instance.getSignOutUrl();
    if (url) {
      await attemptClearCache(); // Even if this fails, user should be signed out
      await signOut({
        callbackUrl: `${url}?post_logout_redirect_uri=${redirectUrl ||
          window.location.origin}`,
      });
    }
  }, []);

  return {
    b2cSignout,
  };
}

export default useSignOut;
