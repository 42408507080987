import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import BackendService from '../services/backendService';
import { generateUniqueId } from '../utils/general';
import { dataURLtoFile } from '../utils/imageCropUtils';
import useAccessBadge from './useAccessBadge';
import useRouterMatch from './useRouterMatch';

export const useProfilePhoto = (tenantId: string) => {
  const { tenantId: activeTenantId } = useRouterMatch();
  const cacheKey = ['profile-photo', activeTenantId];

  const { accessBadge: userAccessBadge } = useAccessBadge();
  const client = useQueryClient();

  const { data, isFetching } = useQuery<string | undefined, string>(
    cacheKey,
    async () => {
      if (!activeTenantId) return Promise.resolve(undefined);
      return (await BackendService.instance.profile.getProfileImageUrl(activeTenantId))
        .data;
    },
    {
      retry: false,
      enabled: Boolean(activeTenantId),
      staleTime: Infinity,
    },
  );

  const { mutate: upload, isLoading: isUploading } = useMutation(
    async ({ imgData }: { imgData: string }) => {
      if (userAccessBadge?.user.id) {
        const profileImageId = userAccessBadge.user.id + '_' + generateUniqueId();
        const file = dataURLtoFile(imgData, profileImageId);
        if (file) {
          const data = await BackendService.instance.profile.getUploadUrlV2({
            imageId: profileImageId,
            mimeType: file.type || '',
            imageType: 'profileImage',
          });
          const url = data.data.url;
          await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.onreadystatechange = function() {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  resolve(xhr.response);
                } else {
                  reject(xhr.response);
                }
              }
            };
            xhr.onerror = function(response) {
              reject(response);
            };
            xhr.send(file);
          });
          await BackendService.instance.markUploadSuccessfulV2(
            data.data.uploadId,
            tenantId,
          );
          return BackendService.instance.profile.updateProfileImageId(profileImageId);
        }
      }
      return Promise.reject();
    },
    {
      onSuccess: async (profileImageId: AxiosResponse<string>) => {
        if (profileImageId) {
          await BackendService.instance.discover.updateCreatorProfileImage(
            profileImageId.data,
          );
        }
        client.invalidateQueries(cacheKey);
        client.invalidateQueries(['templates']);
        client.invalidateQueries(['front-page-templates']);
        client.invalidateQueries(['template-search']);
      },
    },
  );

  return {
    photo: data,
    upload,
    isLoading: isFetching,
    isUploading,
  };
};
