import Link from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import EnterprisePlanIcon from '../../../assets/icons/EnterprisePlanIcon';
import theme from '../../../assets/theme';
import { LicenseCoarseLevels, LicenseFineLevels } from '../../../hooks/useAccessBadge';
import usePaywallPrompt from '../../../hooks/usePaywallPrompt';
import { IconWrapper } from '../../_atoms/IconWrapper';

const HighlightedDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.orange};
  border-radius: 5px;
  padding: 5px;
`;

const PlanIndicator = ({ isMobileDrawer }: { isMobileDrawer: boolean }) => {
  const { isReady, promptPaywall, paywallComponent, userAccessBadge } = usePaywallPrompt({
    startState: { screen: 'schoolPlanPaywall' },
  });
  const { t } = useTranslation('Host');

  const handleShowPaywall = () => {
    if (isReady) {
      promptPaywall({ label: 'Upgrade account', description: 'Plan Indicator' });
    }
  };

  const userLicenseLabel = useMemo(() => {
    if (
      !userAccessBadge ||
      userAccessBadge.license.coarseLevel === LicenseCoarseLevels.freemium
    ) {
      return 'Free plan';
    } else if (userAccessBadge.license.coarseLevel === LicenseCoarseLevels.premium) {
      return 'Premium';
    } else if (userAccessBadge.license.fineLevel === LicenseFineLevels.trial) {
      return (
        '⌛ ' +
        new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(new Date(userAccessBadge.license.expiresAt ?? 0))
      );
    } else {
      return 'School plan';
    }
  }, [userAccessBadge]);

  return (
    <Link
      href={
        userAccessBadge?.license.fineLevel === LicenseFineLevels.trial
          ? '/school-plan-trial'
          : '/profile'
      }
      passHref
    >
      <div
        style={{
          width: '100%',
        }}
      >
        {paywallComponent}
        <div>
          <span style={{ fontSize: 16 }}>
            {!userAccessBadge ||
            userAccessBadge.license.coarseLevel === LicenseCoarseLevels.freemium
              ? 'Personal'
              : userAccessBadge.license.coarseLevel === LicenseCoarseLevels.premium
              ? 'Premium'
              : userAccessBadge.license.fineLevel === LicenseFineLevels.trial
              ? 'School Plan Trial'
              : userAccessBadge.organization?.name ?? 'School Plan'}
          </span>
        </div>
        <PointedRow $isMobileDrawer={isMobileDrawer}>
          {isReady && (
            <>
              <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                {userLicenseLabel}
              </span>
              <div
                style={{
                  height: 3,
                  width: 3,
                  background: theme.colors.greyDark,
                  borderRadius: 20,
                }}
              ></div>
              {(!userAccessBadge ||
                userAccessBadge.license.coarseLevel === LicenseCoarseLevels.freemium) && (
                <HighlightedDiv style={{ cursor: 'pointer' }} onClick={handleShowPaywall}>
                  <span
                    style={{
                      fontWeight: 'bold',
                      lineHeight: '14px',
                      fontSize: 12,
                      textDecoration: 'underline',
                      color: theme.colors.white,
                      marginRight: 5,
                    }}
                  >
                    {t('Upgrade')}
                  </span>
                  <IconWrapper height="10px" width="10px" color={theme.colors.yellow}>
                    <EnterprisePlanIcon color={theme.colors.white} />
                  </IconWrapper>
                </HighlightedDiv>
              )}
              {(userAccessBadge?.license.coarseLevel === LicenseCoarseLevels.enterprise ||
                userAccessBadge?.license.coarseLevel === LicenseCoarseLevels.premium) && (
                <span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
                  {t('Unlimited lessons')}
                </span>
              )}
            </>
          )}
        </PointedRow>
      </div>
    </Link>
  );
};

export default PlanIndicator;

const PointedRow = styled.div<{ $isMobileDrawer: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;
  height: 20px;

  ${({ $isMobileDrawer }) =>
    $isMobileDrawer
      ? css`
          color: ${theme.colors.white};
        `
      : css`
          color: ${theme.colors.greyDark};
        `}
`;
